<template>
  <div>
    <Overlay :overlay="overlay" />
    <ConfigureColorDialog :dialogConfigureColor="dialogConfigureColor" :StoreObj="StoreObj" @clicked="dialogConfigureColorEmit" />
    <CreateDashboardParameterDialog :StoreObj="StoreObj" :dialogCreateDashboardParameter="dialogCreateDashboardParameter" @clicked="createDashboardParameterDialogEmit" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Operational Parameter</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
             <v-text-field dense outlined class="field_height field_label_size fontSize mt-6" label="Search" v-model="search"></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense class="elevation-0" :search="search" :headers="operationalParameterHeaders" :items="GetAllOperationalParameterList">
          <template v-slot:[`item.tag_type`]="{ item }">
            <div class="FontSize">
              {{ item.tag_type }}
            </div>
          </template>
          <template v-slot:[`item.commissioning_value`]="{ item }">
            <div class="FontSize">
              {{ item.commissioning_value }}
            </div>
          </template>
          <template v-slot:[`item.tag_id`]="{ item }">
            <div class="FontSize">
              {{ item.tag_id }}
            </div>
          </template>
          <template v-slot:[`item.tag_description`]="{ item }">
            <div class="FontSize">
              {{ item.tag_description }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-btn small icon color="green" @click="checkItem(item, 'CONFIGURECOLOR')">
              <v-icon>mdi-invert-colors</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" icon :color="'green'" @click="checkItem(item, 'ACTION')"><v-icon small v-text="'mdi-pencil'"></v-icon></v-btn>
              </template>
              <span v-text="`Edit ${item.tag_description}`"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import ConfigureColorDialog from "@/components/Settings/AlarmConfiguration/Dialogs/ConfigureColorDialog.vue";

import CreateDashboardParameterDialog from "@/components/Settings/OperationalParameter/Dialogs/CreateDashboardParameterDialog.vue";
import { GetAllDashboardParameter } from "@/mixins/GetAllDashboardParameter.js";
export default {
  mixins: [GetAllDashboardParameter],
  components: {
    Overlay,
    ConfigureColorDialog,
    CreateDashboardParameterDialog,
  },
  data: () => ({
    search:"",
    overlay: false,
    dialogConfigureColor: false,
    StoreObj: {},
    GetAllOperationalParameterList: [],
    operationalParameterHeaders: [
      { text: "Tag ID", value: "tag_id" },
      { text: "Tag Name", value: "tag_description" },
      { text: "Commissioning Value", value: "commissioning_value" },
      { text: "Action", value: "Actions" },
    ],
    dialogCreateDashboardParameter: false,
  }),
  watch: {
    GetAllDashboardParameterList(val) {
      if (val && val.length != 0) {
        this.GetAllOperationalParameterList = val.filter((tag) => tag.tag_type == "AI" && tag.show_on_dashboard == true);
      }
    },
  },
  mounted() {
    this.GetAllDashboardParameterMethod(this.$store.getters.get_asset_object.asset_id);
  },
  methods: {
    dialogConfigureColorEmit(Toggle) {
      this.dialogConfigureColor = false;
      if (Toggle == 2) {
        this.GetAllDashboardParameterMethod(this.$store.getters.get_asset_object.asset_id);
      }
    },
    createDashboardParameterDialogEmit(Toggle) {
      this.dialogCreateDashboardParameter = false;
      if (Toggle == 2) {
        this.GetAllDashboardParameterMethod(this.$store.getters.get_asset_object.asset_id);
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      switch (action) {
        case "CONFIGURECOLOR":
          this.dialogConfigureColor = true;
          break;
        case "ACTION":
          this.dialogCreateDashboardParameter = true;
          break;
      }
    },
  },
};
</script>
