var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('ConfigureColorDialog',{attrs:{"dialogConfigureColor":_vm.dialogConfigureColor,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogConfigureColorEmit}}),_c('CreateDashboardParameterDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogCreateDashboardParameter":_vm.dialogCreateDashboardParameter},on:{"clicked":_vm.createDashboardParameterDialogEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("Operational Parameter")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-text-field',{staticClass:"field_height field_label_size fontSize mt-6",attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","search":_vm.search,"headers":_vm.operationalParameterHeaders,"items":_vm.GetAllOperationalParameterList},scopedSlots:_vm._u([{key:"item.tag_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.tag_type)+" ")])]}},{key:"item.commissioning_value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.commissioning_value)+" ")])]}},{key:"item.tag_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.tag_id)+" ")])]}},{key:"item.tag_description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.tag_description)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"green"},on:{"click":function($event){return _vm.checkItem(item, 'CONFIGURECOLOR')}}},[_c('v-icon',[_vm._v("mdi-invert-colors")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":'green'},on:{"click":function($event){return _vm.checkItem(item, 'ACTION')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(("Edit " + (item.tag_description)))}})])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }