<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateDashboardParameter">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create Master Tag</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createDashboardParameterDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" class="pb-0">
                <v-text-field
                  dense
                  outlined
                  label="Tag ID"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.tag_id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <v-text-field
                  dense
                  outlined
                  label="Tag Description"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.tag_description"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <v-text-field
                  dense
                  outlined
                  label="Commissioning Value"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.commissioning_value"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="createDashboardParameterDialogEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreateDashboardParameter: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    Dashboardtag_type: "",
    loading: false,
    DashboardParameter_email_id: "",
    SnackBarComponent: {},
    create_dashboard_paramater: {
      tag_id: "",
      tag_description: "",
      commissioning_value: "",
    },
    parameterTypeItems: [
      { text: "Operational", value: "OPERATIONAL" },
      { text: "Summary", value: "SUMMARY" },
    ],
    summaryParameterTypeItems: ["UF", "RO1", "RO2"],
    statusOrFeedbackItems: [
      { text: "Status", value: "STATUS" },
      { text: "Feedback", value: "FEEDBACK" },
      { text: "S", value: "FEEDBACK" },
    ],
  }),
  watch: {
    dialogCreateDashboardParameter(val) {
      if (val == true) {
        console.log("StoreObj", this.StoreObj);
        if (this.StoreObj.action == "ACTION") {
          this.create_dashboard_paramater.tag_id = this.StoreObj.tag_id;
          this.create_dashboard_paramater.tag_description = this.StoreObj.tag_description;
          this.create_dashboard_paramater.commissioning_value = this.StoreObj.commissioning_value;
          this.$forceUpdate();
        }
      }
    },
  },
  methods: {
    getItem(item) {
      item.dependent_color = "YELLOW";
      this.create_dashboard_paramater.parameter_values.map((obj, idx) => {
        let Object = obj;
        if (idx != this.create_dashboard_paramater.parameter_values.indexOf(item)) {
          Object.is_conditional = false;
          Object.dependent_tag_id = "";
          Object.dependent_tag_value = "";
          Object.dependent_color = "";
        }
        return Object;
      });
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.allCommonMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async allCommonMethod() {
      var mutationname = "updatecommisioningvalue";
      var inputParams = {
        tag_id: this.StoreObj.tag_id,
        asset_id: this.$store.getters.get_asset_object.asset_id,
        organization_id: this.$store.getters.get_current_user_details.organization_id,
        user_id: this.$store.getters.get_current_user_details.user_id,
        commissioning_value: this.create_dashboard_paramater.commissioning_value,
      };

      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.createDashboardParameterDialogEmit((this.Toggle = 2));
            this.loading = false;
          } else {
            this.loading = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorType,
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    createDashboardParameterDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
