<template>
  <v-dialog v-model="dialogConfigureColor" persistent max-width="600px">
    <v-card>
      <v-toolbar dark dense class="elevation-0" color="primary">
        <div>Configure Color</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogConfigureColorEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="mt-5 pb-0">
        <v-card outlined class="pa-2">
          <v-form ref="formColorSettings">
            <v-row no-gutters>
              <v-col cols="12" sm="12" xs="12" md="4">
                <v-select
                  outlined
                  dense
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  label="Color"
                  :items="setColorItems"
                  v-model="color_settings_object.color"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" xs="12" md="3" class="px-2">
                <v-text-field
                  outlined
                  dense
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  label="Start Range"
                  v-model="color_settings_object.start"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" xs="12" md="3" class="px-2">
                <v-text-field outlined dense class="field_height field_label_size FontSize" label="End Range" v-model="color_settings_object.end"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" xs="12" md="2">
                <v-btn small color="primary" @click="validateMethod()">Add</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table dense class="elevation-0" :items="color_settings" :headers="colorHeaders">
            <template v-slot:[`item.color`]="{ item }">
              <div class="FontSize">{{ item.color }}</div>
            </template>
            <template v-slot:[`item.start`]="{ item }">
              <div class="FontSize">{{ item.start }}</div>
            </template>
            <template v-slot:[`item.end`]="{ item }">
              <div class="FontSize">{{ item.end }}</div>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-icon color="red" @click="color_settings.splice(color_settings.indexOf(item), 1)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogConfigureColorEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
        <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="configureColorMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { API } from "aws-amplify";
export default {
  props: {
    dialogConfigureColor: Boolean,
    StoreObj: Object,
  },
  data: () => ({
    loading: false,
    color_settings_object: {
      color: "",
      start: "",
      end: "",
    },
    colorHeaders: [
      { text: "Color", value: "color" },
      { text: "Start", value: "start" },
      { text: "End", value: "end" },
      { text: "Action", value: "Action" },
    ],
    setColorItems: ["RED", "GREEN", "YELLOW"],
    color_settings: [],
  }),
  watch: {
    dialogConfigureColor(value) {
      if (value == true) {
        if (this.StoreObj.color_settings) {
          this.color_settings = this.StoreObj.color_settings;
        } else {
          this.color_settings = [];
        }
      }
    },
  },
  methods: {
    async configureColorMethod() {
      var mutationname = "removealarmconfiguration";
      var inputParams = {
        command: "setColorOfOperationParameters",
        tag_id: this.StoreObj.tag_id,
        asset_id: this.$store.getters.get_asset_object.asset_id,
        organization_id: this.$store.getters.get_current_user_details.organization_id,
        color_settings: this.color_settings,
        updater_email_id: this.$store.getters.get_user_email,
      };

      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.dialogConfigureColorEmit((this.Toggle = 2));
            this.loading = false;
          } else {
            this.loading = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorType,
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    validateMethod() {
      if (this.$refs.formColorSettings.validate()) {
        let color_settings = { ...this.color_settings_object };
        color_settings.start = Number(color_settings.start);
        color_settings.end = Number(color_settings.end);
        this.color_settings.push(color_settings);
        this.$refs.formColorSettings.reset();
      }
    },
    dialogConfigureColorEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
